import React, { useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { IoLocationSharp } from "react-icons/io5";
import VerifyOtp from '../components/VerifyOtp';
import { GetLocation, GetStateUsingPincode, UpdateDetails } from '../Common/ServerApi';
import spinner from '../assets/img/spinner.gif';
import { useLocation, useNavigate } from 'react-router-dom';
import countrycode from './countrycode.json';

interface FormData {
    first_name: string;
    last_name: string;
    phone: string;
    country_code:string;
    pet_name: string;
    type: string;
    gender: string;
    breed: string;
    dob: string;
    age: string;
    address: string;
    area: string;
    pincode: string;
    city: string;
    state: string;
    country: string;
    id: string;
}

interface AddressData {
    address: string;
    area: string;
    pincode: string;
    city: string;
    state: string;
    country: string;
}

const EditDetails: React.FC = () => {
    const [verify, setVerify] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const editData: Partial<FormData> = location.state?.data || {};
    const [id, setId] = useState<string>(editData?.id || "");
    const navigate = useNavigate();



    const [formData, setFormData] = useState<FormData>({
        first_name: editData.first_name || '',
        last_name: editData.last_name || '',
        phone: editData.phone || '',
        country_code: editData.country_code || '',
        type: editData.type || '',
        gender: editData.gender || '',
        breed: editData.breed || '',
        pet_name: editData.pet_name || '',
        dob: editData.dob || '',
        address: editData.address || '',
        age: editData.age || '',
        area: editData.area || '',
        pincode: editData.pincode || '',
        city: editData.city || '',
        state: editData.state || '',
        country: editData.country || '',
        id: editData.id || '',
    });

    const handleClose = () => {
        setVerify(false);
    };

    const calculateAge = (birthdate: string): number => {
        const birthDate = new Date(birthdate);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };


    const handleChange = (e: any) => {
        const { name, value } = e.target;

        if (name === 'dob') {
            const age = calculateAge(value);
            setFormData((prevData: any) => ({
                ...prevData,
                [name]: value,
                age: age || ''
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }


    };
    const handlePincodeChange = (e: any) => {
        const { name, value } = e.target;
        if (/^\d{0,6}$/.test(value)) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }

        if (name === 'pincode' && value.length < 5) {
            setFormData((prev) => ({
                ...prev,
                city: '',
                state: '',
                country: '',
            }));

        }

        if (name === 'pincode' && value.length === 6) {
            fetchCityStateByPincode(value);
        }
    };
    const fetchCityStateByPincode = async (pincode: any) => {
        try {
            const response = await GetStateUsingPincode(pincode)
            if (response?.status === true) {
                const { District, State, Country } = response?.data[0];
                setFormData((prev) => ({
                    ...prev,
                    city: District,
                    state: State,
                    country: Country,
                }));
            } else {
                alert('Invalid Pincode');
            }
        } catch (error) {
            console.error('Error fetching city and state:', error);
        }
    };

    const handlePhoneChange = (e: any) => {
        const { name, value } = e.target;
    
        if (/^\d*$/.test(value) && value.length <= 10) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    const handlecountry_codeChange = (e:any) => {
        setFormData({ ...formData, country_code: e.target.value });
      };
    const handleAgeChange = (e: any) => {
        const { name, value } = e.target;
        if (/^\d{0,3}$/.test(value)) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const {
            first_name,
            last_name,
            phone,
            country_code,
            pet_name,
            type,
            gender,
            breed,
            dob,
            age,
            address,
            area,
            pincode,
            city,
            country,
            state,
        } = formData;

        const getValueOrNull = (value: any) => (value ? value : null);

        try {
            const response = await UpdateDetails(
                getValueOrNull(first_name),
                getValueOrNull(last_name),
                getValueOrNull(Number(phone)),
                getValueOrNull(Number(country_code)),
                getValueOrNull(pet_name),
                getValueOrNull(type),
                getValueOrNull(gender),
                getValueOrNull(breed),
                getValueOrNull(dob),
                getValueOrNull(Number(age)),
                getValueOrNull(address),
                getValueOrNull(area),
                getValueOrNull(Number(pincode)),
                getValueOrNull(city),
                getValueOrNull(country),
                getValueOrNull(state),
                getValueOrNull(editData?.id),
            );


            if (response?.status === true) {
                setLoading(false);
                navigate('/generateqrcode', { state: { imageData: response?.data } });

            } else {
                console.log('OTP not sent');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
        }
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const response = await GetLocation(latitude, longitude);
                if (response?.status === true) {
                    setFormData({
                        ...formData,
                        address: response?.data.address,
                        area: response?.data.area,
                        pincode: response?.data.pincode,
                        city: response?.data.city,
                        state: response?.data.state,
                        country: response?.data.country
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }, (error) => {
                console.error("Error getting location", error);
                setLoading(false);
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    return (
        <>
            <Container>
                <Row>
                    <Col lg={4} className='p-0 mx-auto position-relative'>
                        {loading && (
                            <div className="overlay">
                                <img src={spinner} />
                            </div>
                        )}
                        <div className='bg_color position-relative bg_img'>
                            <div className='position-relative'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="390" height="119" viewBox="0 0 390 119" fill="none" className='z-1'>
                                    <path d="M91.9756 118.946C54.6807 117.406 45.3404 74.8318 8.04627 73.2746C4.51657 73.1272 -1 73.2746 -1 73.2746V0H390C390 0 364.049 43.327 337.733 59.7238C289.777 89.6039 247.09 43.3626 192.992 59.7238C149.211 72.9649 137.682 120.832 91.9756 118.946Z" fill="url(#paint0_linear_207_798)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_207_798" x1="-1" y1="0" x2="65.2941" y2="217.824" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#EECE13" />
                                            <stop offset="1" stopColor="#B210FF" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className='heading_details position-absolute top-10 z-2 text-white'>
                                    Edit Details
                                </div>
                            </div>
                            <div className='radius pt-2'>
                                <Form autoComplete='off' onSubmit={handleSubmit}>
                                    <h5>Owner Details</h5>
                                    <Row>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingInput" label="First Name" className="mb-3">
                                                <input type="text" placeholder="First Name" className="form-control" name="first_name" value={formData.first_name} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingPassword" label="Last Name" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Last Name" name="last_name" value={formData.last_name} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        
                                        <Col lg={12}>
                                        <div className="form-group position-relative mb-3">
                                            <label htmlFor="phone" className="floating-label phone_label ">Phone</label>
                                            <div className="d-flex align-items-center">
                                            <select
                                                className="form-select w-auto me-2"
                                                name="country_code"
                                                value={formData?.country_code}
                                                onChange={handlecountry_codeChange}
                                            >
                                                {countrycode?.map((country:any, index:any) => (
                                                <option key={index} value={country?.code}>
                                                    + {country?.code}
                                                </option>
                                                ))}
                                            </select>
                                            <input className="form-control" type="text" placeholder="Phone" name="phone" value={formData.phone} onChange={handlePhoneChange} required minLength={8} maxLength={10} />

                                            </div>
                                        </div>
                                        </Col>
                                        <h5 className='mt-4'>Pet Details</h5>
                                        <Col lg={12}>
                                            <FloatingLabel controlId="floatingPetname" label="Name" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Name" name="pet_name" value={formData.pet_name} onChange={handleChange} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={12}>
                                            <FloatingLabel controlId="floatingType" label="Type" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Type" name="type" value={formData.type} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={12}>
                                            <FloatingLabel controlId="floatingSelect" label="Gender" className="mb-3">
                                                <Form.Select name="gender" value={formData.gender} onChange={handleChange} required>
                                                    <option>Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={12}>
                                            <FloatingLabel controlId="floatingBreed" label="Breed" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Breed" name="breed" value={formData.breed} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingDob" label="Date of Birth" className="mb-3">
                                                <input className="form-control" type="date" placeholder="Date of Birth" name="dob" value={formData.dob} onChange={handleChange} max={new Date().toISOString().split("T")[0]} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingAge" label="Age" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Age" name="age" value={formData.age} onChange={handleAgeChange} />
                                            </FloatingLabel>
                                        </Col>

                                        <Col className='text-center mt-4'>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props}>
                                                        Fetching Data from Thirdparty and get address information
                                                    </Tooltip>
                                                )}
                                                placement="top"
                                            >
                                                <Button
                                                    variant="success"
                                                    className='mb-2 btn-current'
                                                    onClick={getCurrentLocation}
                                                    disabled={loading}
                                                >
                                                    <IoLocationSharp className="pe-2 icon-size" />
                                                    {loading ? 'Loading...' : 'Get Current Location'}
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>

                                        <Col lg={12}>
                                            <FloatingLabel controlId="floatingPassword" label="Address" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Address" name="address" value={formData.address} onChange={handleChange} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={12}>
                                            <FloatingLabel controlId="floatingPassword" label="Area" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Area" name="area" value={formData.area} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingPassword" label="Pincode" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Pincode" name="pincode" value={formData.pincode} onChange={handlePincodeChange} required minLength={4} maxLength={6} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingPassword" label="City" className="mb-3">
                                                <input className="form-control" type="text" placeholder="City" name="city" value={formData.city} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingPassword" label="State" className="mb-3">
                                                <input className="form-control" type="text" placeholder="State" name="state" value={formData.state} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        <Col lg={6}>
                                            <FloatingLabel controlId="floatingPassword" label="Country" className="mb-3">
                                                <input className="form-control" type="text" placeholder="Country" name="country" value={formData.country} onChange={handleChange} required />
                                            </FloatingLabel>
                                        </Col>
                                        <Col className='text-center'>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props}>
                                                        Mobile OTP Authentication before Generating QR
                                                    </Tooltip>
                                                )}
                                                placement="top"
                                            >
                                                <Button type='submit' variant='success' className="btn-current mt-4" >
                                                    Update Deatils
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                        <div className='bottom_svg_vector'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="274" viewBox="0 0 100 274" fill="none">
                                <path d="M-268.968 170.541C-269.874 204.334 -251.675 251.123 -214.212 242.675C-185.895 236.29 -211.661 204.154 -176.84 185.981C-142.019 167.807 -96.5901 173.269 -74.8614 203.192C-58.8608 225.226 -87.2195 246.254 -74.8614 270.01C-46.4556 324.615 94.4445 312.594 99.8342 252.799C103.407 213.164 48.2306 207.401 31.1728 170.541C10.4472 125.757 52.936 85.6182 19.2946 47.2812C-6.14661 18.2889 -35.2008 -8.84112 -74.8614 2.73538C-126.369 17.77 -25.6103 104.234 -74.8614 124.224C-97.7427 133.511 -114.512 127.469 -139.467 124.224C-169.972 120.257 -184.289 95.4283 -214.212 101.951C-250.481 109.857 -268.092 137.893 -268.968 170.541Z" fill="url(#paint0_linear_207_795)" fillOpacity="0.59" />
                                <defs>
                                    <linearGradient id="paint0_linear_207_795" x1="-269" y1="0" x2="30.5491" y2="362.405" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF6FD8" />
                                        <stop offset="1" stopColor="#3813C2" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </Col>
                </Row>
            </Container>
            <VerifyOtp add={verify} handleClose={handleClose} id={id} />
        </>
    );
};

export default EditDetails;
