import axios, { AxiosRequestConfig } from "axios";
// API configurations
export const ServerAPI = {
  APIMethod: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  } as const,
};

export const API = {
  BaseUrl: "https://www.animalhearts.in/api/",
  // BaseUrl: "http://192.168.1.10:5011/api/",
};

export const EndPoint = {
  sendotp: "send-otp",
  verifyotp: "verify-otp",
  getdetails: "get-user-details",
  updatedetails: 'update-user-details',
  getstateinfobypin: 'get-state-info-by-pin',
  getaddressinfo: 'get-address-info',
};

export interface SendOtp {
  first_name: string,
  last_name: string,
  phone: number,
  country_code: number,
  pet_name: string,
  type: string,
  gender: string,
  breed: string,
  dob: string,
  age: number,
  address: string,
  area: string,
  pincode: number,
  city: string,
  country: string,
  state: string,
}

export interface UpdateDetails {
  first_name: string,
  last_name: string,
  phone: number,
  country_code: number,
  pet_name: string,
  type: string,
  gender: string,
  breed: string,
  dob: string,
  age: number,
  address: string,
  area: string,
  pincode: number,
  city: string,
  country: string,
  state: string,
}

export const SendOtp = async (first_name: string, last_name: string, phone: number,country_code:number, pet_name: string, type: string, gender: string, breed: string, dob: string, age: number, address: string, area: string, pincode: number, city: string, country: string, state: string) => {
  const data: SendOtp = { first_name, last_name, phone,country_code, pet_name, type, gender, breed, dob, age, address, area, pincode, city, country, state };
  return await executeAPI(ServerAPI.APIMethod.POST, data, EndPoint.sendotp);
};

export const UpdateDetails = async (first_name: string, last_name: string, phone: number,country_code:number, pet_name: string, type: string, gender: string, breed: string, dob: string, age: number, address: string, area: string, pincode: number, city: string, country: string, state: string, id: string) => {
  const data: UpdateDetails = { first_name, last_name, phone,country_code, pet_name, type, gender, breed, dob, age, address, area, pincode, city, country, state };
  return await executeAPI(ServerAPI.APIMethod.POST, data, `${EndPoint.updatedetails}/${id}`);
};


export interface VeriftOtp {
  otp: number,
  id: string
}


export const VerifyOtp1 = async (otp: number, id: string) => {
  const data: VeriftOtp = { otp, id };
  return await executeAPI(ServerAPI.APIMethod.POST, data, EndPoint.verifyotp);
};


export const GetDetails = async (token: any) => {
  return await executeAPI(ServerAPI.APIMethod.GET, null, `${EndPoint.getdetails}/${token}`);
};

export const GetStateUsingPincode = async (pincode: any) => {
  return await executeAPI(ServerAPI.APIMethod.GET, null, `${EndPoint.getstateinfobypin}?pincode=${pincode}`);
};

export const GetLocation = async (latitude: any, longitude: any) => {
  return await executeAPI(ServerAPI?.APIMethod?.GET, null, `${EndPoint?.getaddressinfo}?latitude=${latitude}&longitude=${longitude}`);
};



export const executeAPI = async (
  method: keyof typeof ServerAPI.APIMethod,
  data: any,
  endpoint: string,
  params?: any
): Promise<any> => {
  try {
    const config: AxiosRequestConfig = {
      method: method,
      url: `${API.BaseUrl}${endpoint}`,
      data: data,
      params: params,
      // withCredentials: true,
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.message);
      if (error.code === 'ERR_NETWORK') {
        console.error('Network Error: Please check your connection.');
      }
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};